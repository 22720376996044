import clsx from "clsx";
import React from "react";
import { useTheme } from "../../hooks";
import { AlertThemeType, AlertType } from "../../types";
import { NestedPartial, deepMerge } from "../../utils";
import Alert from "./Alert";
import { Message, MessageWrapper } from "./AlertStyles";

type AlertHolderProps = {
  alerts: AlertType[];
  onRemove: (id: number | string) => void;
  fullWidth?: boolean;
  className?: string;
  styles?: NestedPartial<AlertThemeType>;
};

const AlertHolder: React.FC<React.PropsWithChildren<AlertHolderProps>> = ({
  alerts,
  onRemove,
  fullWidth = false,
  className = "",
  styles = {},
}) => {
  const { Theme } = useTheme();
  const StylesOverride: AlertThemeType = deepMerge<AlertThemeType>(Theme.alert, styles);

  return (
    <div className={clsx("best-alert-holder", className)}>
      {!!alerts?.length && (
        <MessageWrapper>
          <Message style={{ width: fullWidth ? "95%" : "" }}>
            {alerts.map((alert: AlertType, index: number) => (
              <Alert
                id={alert.id}
                type={alert.type}
                // eslint-disable-next-line
                key={`alert-${index}`}
                onRemove={onRemove}
                styles={StylesOverride}
              >
                {alert.text}
              </Alert>
            ))}
          </Message>
        </MessageWrapper>
      )}
    </div>
  );
};

export default AlertHolder;
