import styled from "styled-components";
import { ButtonOverrideStyles } from "./Button";

interface ButtonStyles {
  $styles: ButtonOverrideStyles;
  $isButtonLoading?: boolean;
  $size?: string;
  disabled?: boolean;
}

export const ButtonLinkStyled = styled.button<ButtonStyles>`
  &&& {
    color: ${(props) => props.$styles.link.color};
    font-size: ${(props) => props.$styles.link.size};
    ${(props) => (props.disabled ? `color: ${props.$styles.link.disabled}` : "")};
    ${(props) => (props.disabled ? `opacity: ${props.$styles.link.opacity}` : "")};
    text-decoration: none;
    border-bottom: none;
    &:hover {
      color: ${(props) => props.$styles.link.hover};
    }
  }
`;

export const ButtonAnchorStyled = styled.a<ButtonStyles>`
  &&& {
    color: ${(props) => props.$styles.link.color};
    font-size: ${(props) => props.$styles.link.size};
    ${(props) => (props.disabled ? `color: ${props.$styles.link.disabled}` : "")};
    ${(props) => (props.disabled ? `opacity: ${props.$styles.link.opacity}` : "")};
    text-decoration: none;
    border-bottom: none;
    &:hover {
      color: ${(props) => props.$styles.link.hover};
    }
  }
`;

export const ButtonStyled = styled.button<ButtonStyles>`
  &&& {
    box-shadow: ${(props) => props.$styles.button["boxShadow"]};
    border-radius: ${(props) => props.$styles.button["border-radius"]};
    font-size: ${(props) => props.$styles.button.size};
    padding: ${(props) => props.$styles.button.padding};
    font-weight: ${(props) => props.$styles.button["font-weight"]};
    line-height: ${(props) => props.$styles.button["line-height"]};
    &.btn-primary {
      background-color: ${(props) => props.$styles.button.primary["background-color"]};
      border-color: ${(props) => props.$styles.button.primary["border-color"]};
      color: ${(props) => props.$styles.button.primary.color};
      &:hover {
        background-color: ${(props) => props.$styles.button.primary.hover};
        border-color: ${(props) => props.$styles.button.primary.hover};
      }
    }
    &.btn-secondary {
      background-color: ${(props) => props.$styles.button.secondary["background-color"]};
      border-color: ${(props) => props.$styles.button.secondary["border-color"]};
      color: ${(props) => props.$styles.button.secondary.color};
      &:hover {
        background-color: ${(props) => props.$styles.button.secondary.hover};
      }
    }
    &.btn-danger {
      background-color: ${(props) => props.$styles.button.danger["background-color"]};
      border-color: ${(props) => props.$styles.button.danger["border-color"]};
      color: ${(props) => props.$styles.button.danger.color};
      &:hover {
        background-color: ${(props) => props.$styles.button.danger.hover};
        border-color: ${(props) => props.$styles.button.danger.hover};
      }
    }
    &.btn-light {
      background-color: ${(props) => props.$styles.button.default["background-color"]};
      border-color: ${(props) => props.$styles.button.default["border-color"]};
      color: ${(props) => props.$styles.button.default.color};
      &:hover {
        background-color: ${(props) => props.$styles.button.default.hover};
        border-color: ${(props) => props.$styles.button.default.hover};
      }
    }
    &.btn-success {
      background-color: ${(props) => props.$styles.button.success["background-color"]};
      border-color: ${(props) => props.$styles.button.success["border-color"]};
      color: ${(props) => props.$styles.button.success.color};
      &:hover {
        background-color: ${(props) => props.$styles.button.success.hover};
        border-color: ${(props) => props.$styles.button.success.hover};
      }
    }
    &.btn-loading {
      background-color: ${(props) => props.$styles.button.loading["background-color"]};
      border-color: ${(props) => props.$styles.button.loading["border-color"]};
      div {
        border-color: ${(props) => props.$styles.button.loading.color};
      }
      &:hover {
        background-color: ${(props) => props.$styles.button.loading["background-color"]};
        border-color: ${(props) => props.$styles.button.loading["border-color"]};
      }
    }
    &.btn-sm {
      &&& {
        font-size: ${(props) => props.$styles.button.small.size};
        padding: ${(props) => props.$styles.button.small.padding};
      }
    }
    &.btn-lg {
      &&& {
        font-size: ${(props) => props.$styles.button.large.size};
        padding: ${(props) => props.$styles.button.large.padding};
      }
    }
    &&& {
      ${(props) => (props.disabled ? `background-color: ${props.$styles.button.disabled["background-color"]}` : "")};
      ${(props) => (props.disabled ? `color: ${props.$styles.button.disabled.color}` : "")};
      ${(props) => (props.disabled ? `border-color: ${props.$styles.button.disabled["border-color"]}` : "")};
      ${(props) => (props.disabled ? `opacity: ${props.$styles.button.disabled.opacity}` : "")};
    }
  }
`;
