import React from "react";
import { useTheme } from "../../../hooks";
import { ModalThemeType } from "../../../types";
import { deepMerge, NestedPartial } from "../../../utils";
import ModalBody, { ModalBodyProps } from "./body/ModalBody";
import ModalFooter, { ModalFooterProps } from "./footer/ModalFooter";
import { GenericModalDiv } from "./GenericModalStyles";
import ModalHeader, { ModalHeaderProps } from "./header/ModalHeader";

type GenericModalProps = {
  children: React.ReactElement[] | React.ReactElement;
  styles?: NestedPartial<ModalThemeType>;
};

export interface GenericModalType extends React.FC<GenericModalProps> {
  Header: React.FC<React.PropsWithChildren<ModalHeaderProps>>;
  Body: React.FC<React.PropsWithChildren<ModalBodyProps>>;
  Footer: React.FC<React.PropsWithChildren<ModalFooterProps>>;
}

const GenericModal: GenericModalType = ({ children, styles = {} }) => {
  const { Theme } = useTheme();
  const StylesOverride: ModalThemeType = deepMerge<ModalThemeType>(Theme.modal, styles);
  return (
    <GenericModalDiv styles={StylesOverride}>
      {React.Children.map(children, (child: React.ReactElement) => {
        if (!child || !child.type || !React.isValidElement(child)) {
          return null;
        } else if (child.type === ModalHeader || child.type === ModalBody || child.type === ModalFooter) {
          return child;
        } else if (
          //@ts-ignore
          child.type?.displayName === ModalHeader.displayName ||
          //@ts-ignore
          child.type?.displayName === ModalBody.displayName ||
          //@ts-ignore
          child.type?.displayName === ModalFooter.displayName
        ) {
          return child;
        } else {
          return null;
        }
      })}
    </GenericModalDiv>
  );
};

GenericModal.Header = ModalHeader;
GenericModal.Body = ModalBody;
GenericModal.Footer = ModalFooter;

export default GenericModal;
