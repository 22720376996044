import React from "react";
import { useTheme } from "../../../../hooks";
import { NestedPartial, deepMerge } from "../../../../utils";
import { ModalFooterDiv, ModalFooterStyles } from "./ModalFooterStyles";

export type ModalFooterProps = {
  children: React.ReactNode;
  justifyContent?: string;
  styles?: NestedPartial<ModalFooterStyles>;
};

const ModalFooter: React.FC<React.PropsWithChildren<ModalFooterProps>> = ({
  children,
  justifyContent = "end",
  styles = {},
}) => {
  const { Theme } = useTheme();
  const StylesOverride: ModalFooterStyles = deepMerge<ModalFooterStyles>(Theme.modal.footer, styles);
  return (
    <ModalFooterDiv styles={StylesOverride} justifyContent={justifyContent}>
      {children}
    </ModalFooterDiv>
  );
};

ModalFooter.displayName = "ModalFooter";

export default ModalFooter;
