import React from "react";
import { useTheme } from "../../../../hooks";
import { IconThemeType, ModalHeaderThemeType } from "../../../../types";
import { NestedPartial, deepMerge } from "../../../../utils";
import Icon from "../../../icon/Icon";
import { ModalHeaderDiv } from "./ModalHeaderStyles";

export type ModalHeaderProps = {
  children: React.ReactNode;
  close?: () => void;
  styles?: NestedPartial<ModalHeaderThemeType>;
};

const ModalHeader: React.FC<React.PropsWithChildren<ModalHeaderProps>> = ({ children, styles = {}, close }) => {
  const { Theme } = useTheme();
  const StylesOverride: ModalHeaderThemeType = deepMerge<ModalHeaderThemeType>(Theme.modal.header, styles);
  const IconStylesOverride: IconThemeType = StylesOverride.iconSize
    ? { ...Theme.icon, size: StylesOverride.iconSize }
    : { ...Theme.icon };
  const closeIcon = StylesOverride.closeIcon ?? "fa-times-circle";
  return (
    <ModalHeaderDiv styles={StylesOverride}>
      {children} {!!close && <Icon iconName={closeIcon} onClick={close} styles={IconStylesOverride} />}
    </ModalHeaderDiv>
  );
};

ModalHeader.displayName = "ModalHeader";

export default ModalHeader;
