import React from "react";
import { ModalBodyThemeType } from "types";
import { useTheme } from "../../../../hooks";
import { deepMerge, NestedPartial } from "../../../../utils";
import { ModalBodyDiv } from "./ModalBodyStyles";

export type ModalBodyProps = {
  children: React.ReactNode;
  styles?: NestedPartial<ModalBodyThemeType>;
};

const ModalBody: React.FC<React.PropsWithChildren<ModalBodyProps>> = ({ children, styles = {} }) => {
  const { Theme } = useTheme();
  const StylesOverride: ModalBodyThemeType = deepMerge<ModalBodyThemeType>(Theme.modal.body, styles);
  return <ModalBodyDiv styles={StylesOverride}>{children}</ModalBodyDiv>;
};

ModalBody.displayName = "ModalBody";

export default ModalBody;
